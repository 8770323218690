<div class="mx-auto border-2 bg-white max-w-7xl p-4">
  <div class="header">
    <h5>Tên người nộp thuế: {{ getTargetCompany.tenDoanhNghiep }}</h5>
    <h5>Mã số thuế: {{ getTargetCompany.mst }}</h5>
    <div class="titleTable text-center mt-2 border-2 border-black py-2">
      <p class="font-bold">BẢNG TỔNG HỢP PHÁT SINH PHẢI THU 131</p>
    </div>
    <p class="text-center font-normal mt-1 text-sm">Năm {{ getReportYear }}</p>
  </div>

  <div class="dataTable hscroll mt-2 outer-wrapper">
    <table class="table-fixed min-width-table border text-center w-full text-sm mb-40">
      <thead>
        <tr class="bg-yellow-400">
          <th class="w-12">STT</th>
          <th>Người mua</th>
          <th class="w-14">Tình trạng</th>
          <th class="w-32">MST</th>
          <th class="w-32">Số Tiền</th>
          <th class="w-20">Loại tiền tệ</th>
        </tr>
      </thead>

      <tbody>
        <template v-for="(item, index) in report_131.records" :key="index" v-if="report_131 && report_131.records && report_131.records.length > 0">
          <tr v-for="(sub_item, sub_index) in item">
            <td>{{sub_index+1}}</td>
            <td class="text-left"><span class="whitespace-normal">{{sub_item.company_name}}</span></td>
            <td>
                <img
                    v-if="sub_item.status"
                    src="@/assets/icons/icon_check.svg"
                    alt=""
                    class="w-4 h-4 fill-color-green inline-block"
                    :class="{
                      'fill-color-red': 
                        sub_item.status.includes('ngừng') ||
                        sub_item.status.includes('nghỉ') ||
                        sub_item.status.includes('không') ||
                        sub_item.status.includes('dừng')
                    }"
                />
                <img
                    v-else
                    src="@/assets/icons/icon_check.svg"
                    alt=""
                    class="w-4 h-4 fill-color fill-color-orange inline-block"
                />
            </td>
            <td>{{sub_item.mst}}</td>
            <td>
              <input-money
                v-model.lazy="sub_item.sum_product_amount"
                class="money"
                :invoiceStyle="true"
                :table="true"
                :decima="0"
              />
            </td>
            <td>{{ sub_item.currency }}</td>
          </tr>
          <tr v-if="is_load_more">
            <td colspan="6" class="text-yellow-700">
              <p class="p-2 font-bold"><i class="el-icon-warning-outline mr-2"/>Dữ liệu có thể thiếu, vui lòng tải tệp để xem dữ liệu đầy đủ</p>
            </td>
          </tr>

          <tr class="bg-yellow-400 font-bold">
            <td colspan="4">
              <p class="p-1 text-left">Tổng ({{item[0].currency}})</p>
            </td>
            <td>
              <input-money
                v-model.lazy="report_131.aggregates[index]"
                class="money font-bold"
                :invoiceStyle="true"
                :table="true"
                :decima="0"
              />
            </td>
            <td>{{item[0].currency}}</td>
          </tr>

        </template>
        <tr v-else>
          <td colspan="6"><p class="p-3">Dữ liệu trống</p></td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
